import styled from 'styled-components'

export const ContRedes = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const ContRedesIcon = styled.div`
  width: 45px;
  height: 45px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 768px){
    width: 45px;
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const ALinkFacebook = styled.a`
  width: 100%;
  height: 100%;

  @media(min-width: 300px) and (max-width: 768px){
    
  }
  @media(min-width: 769px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ALinkTwitter = styled.a`
  width: 100%;
  height: 100%;
  
  @media(min-width: 300px) and (max-width: 768px){
    
  }
`
export const ALinkInstagram = styled.a`
  width: 100%;
  height: 100%;
  
  @media(min-width: 300px) and (max-width: 768px){
    
  }
`
export const ALinkLinkedin = styled.a`
  width: 100%;
  height: 100%;
  
  @media(min-width: 300px) and (max-width: 768px){
    
  }
`
