import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {ContRedes, ContRedesIcon, ALinkFacebook, ALinkInstagram} from './stylesRedesSVG';
import IcoFacebook from '../../images/ico-facebook.svg';
import IcoInstagram from '../../images/ico-instagram.svg';

export const RedesSVG = props => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            linkFacebook
            linkInstagram
          }
        }
      }
    `
  )

  const linkFacebook = site.siteMetadata.linkFacebook;
  const linkInstagram = site.siteMetadata.linkInstagram;

  //console.log('linkFacebook => ', linkFacebook);
  //console.log('linkInstagram => ', linkInstagram);


  return(
    <ContRedes>
      <ContRedesIcon>
        <ALinkFacebook href={linkFacebook} target="_blank">
          <img src={IcoFacebook} alt={''}/>
        </ALinkFacebook>
      </ContRedesIcon>
      <ContRedesIcon>
        <ALinkInstagram href={linkInstagram} target="_blank">
          <img src={IcoInstagram} alt={''}/>
        </ALinkInstagram>
      </ContRedesIcon>
    </ContRedes>
  )
}
