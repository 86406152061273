import React from 'react';
import {
  ContFooter,
  ContLogo,
  ContContact,
  IcoContact,
  TextContact,
  FooterDev,
  TextoDev,
  TitleSection,
  ListSection, LinkSection, LinkSectionSmall, ContRedes
} from "./styles";
import {Column, Columns, Container} from "bloomer";
import {LogoPrimary} from "../Logo/logoPrimary";
import {RedesSVG} from "../Redes/redesSVG";

import IcoPhone from '../../images/ico-phone.svg';
import IcoPin from '../../images/ico-pin.svg';

const FooterCopmp = ({data}) => {

  return (
    <ContFooter>
      <Container>
        <Columns
          className="columns-responsive columns-margin-side-no full-height"
          isMultiline={true}
        >
          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <div>
              <ContLogo>
                <LogoPrimary/>
              </ContLogo>
              <ContContact>
                <IcoContact><img src={IcoPhone} alt={'Phone'}/></IcoContact>
                <TextContact><a href='tel:+529545820489'>954 582 0489</a></TextContact>
              </ContContact>
              <ContContact>
                <IcoContact><img src={IcoPin} alt={'Map'}/></IcoContact>
                <TextContact>
                  <div>2da. Norte, Col. Centro.</div>
                  <div>71980 Puerto Escondido,</div>
                  <div>México</div>
                </TextContact>
              </ContContact>
            </div>
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <Container>
              <Columns
                className="columns-responsive columns-margin-side-no full-height"
                isMultiline={true}
              >
                <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
                  <TitleSection>MENÚ</TitleSection>
                  <ListSection>
                    <li><LinkSection to={'/'}>Inicio</LinkSection></li>
                    <li><LinkSection to={'/lifestyle'}>Life Style</LinkSection></li>
                    <li><LinkSection to={'/hablemos'}>Hablemos</LinkSection></li>
                  </ListSection>
                </Column>
                <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
                  <TitleSection>Desarrollos Inmobiliarios</TitleSection>
                  <ListSection>
                    <li><LinkSection to={'/puntamar'}>Punta Mar</LinkSection></li>
                    <li><LinkSection to={'/oxean'}>Oxean</LinkSection></li>
                    <li><LinkSection to={'/zul'}>Zul</LinkSection></li>
                  </ListSection>
                  <ContRedes>
                    <RedesSVG/>
                  </ContRedes>
                </Column>
              </Columns>
            </Container>
          </Column>
        </Columns>
      </Container>
      <Container>
        <Columns>
          <Column className={'flex-center padding-top-30'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          <ListSection>
            <li><LinkSectionSmall to={'/aviso-de-privacidad'}>Aviso de privacidad</LinkSectionSmall></li>
          </ListSection>
          </Column>
        </Columns>
      </Container>
      <FooterDev>
        <Container>
          <Columns className="columns-responsive columns-margin-side-no full-height">
            <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
              <TextoDev>Todos los derechos reservados para The Ocean Group 2022 | Diseñado por Homónimo - Dev por
                Guzbarraf</TextoDev>
            </Column>
          </Columns>
        </Container>
      </FooterDev>
    </ContFooter>
  )
}

export default FooterCopmp
